
.card-pricing {    
    grid-column: 2/4;
    display: flex;
    gap: 0 4rem;   
    line-height: 2;
    cursor:pointer;
    /* min-width: 50vw;
    /* width: 100%; */
    /* max-width:max-content; */
}

.card-section{
    /* grid-column:1/5; */
    display:flex;
    gap:1rem 2rem;
}
.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius:1rem;
    min-width: 390px;
}

.imgOne {
    height: -webkit-fill-available;
    max-width: 20rem;
    border-radius: 1rem;    
    transition: all .4s ease-in-out ;
}

img:hover {
    transform: scale(1.06);
    /*  */
}

.card-article {
    display: grid;
    gap: .5rem;
    /* color: var(--blue-accent); */
    font-family:  system-ui,'Poetsen One',sans-serif;
    width:clamp(350px, 365px,450px);
    letter-spacing: .1px;
}
.card-article-list {
    display: grid;
    gap: 1rem;
}

.clear-pricing {
    grid-column: 1/5;    
    font-size:2rem;
    text-align:center; 
}

.card-col-1 {
    display:flex;
    flex-direction: column;
    gap:1rem;
}

.diamond::before {
    content:'💎 '
}
.card-article li::before {
    content:'➡️ ';
  }
.arrow {
    color:chartreuse;
    text-shadow: 0px 1px 2px #000000;
}

@media screen and (max-width:570){   
   .card-pricing {   
    /* flex-direction:column; */
    display:block;
   }
   .clear-pricing {   
    font-size:1.3rem;
    }
}