/* Main Content */
main {
    grid-column: 2/3;
    /* padding: 20px; */
    /* display:grid; */
    /* grid-template-columns: repeat(5,1fr); */
    grid-template-columns: .8fr 4fr .2fr 4fr ;
 /* grid-template-columns: repeat(1,1fr); */
    grid-template-rows: 40px 1fr 5fr 3fr; 
    grid-template-rows: auto;
    /* gap: 1rem; */
    max-width: 850px;
  }
   
  .body-section {
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
  }
  
   ul:is(.pricing-ul) li::marker {
    list-style-type: none;
    /* color: goldenrod; */
  }

  

  li, details summary, details p{
    margin: 0px;
    color: #0902cf;
    font-weight: 500;
  }

  details p{
    margin: 0px;
    color: hsl(240, 3%, 7%);
    font-size: smaller;
  }

 details summary::after {
    /* content:url(
        'https://th.bing.com/th/id/OIP.0jt8LgRGLMaZ_Gora51APAAAAA?rs=1&pid=ImgDetMain'
        ); */
    content: '   ✓';
    color: green !important;    
    font-weight: 900;
    /* margin-left: 4px;    */
  }  

  .main1{
    grid-column:2/4;
    grid-row: 2/3;
    min-width: 370px;    
  }
  .main2{
    grid-column:4/5;
    grid-row: 2/3;
    min-width: 370px;
  }

 
  .sub-header {
    grid-column:2/5;
    font-family: "Indie Flower";
    text-align: center;
  }

  .learn-more::after{
    /* content:'  ↓'; */
    /* content:' '; */
    color: #ff00b3;
    font-weight:900;
    }

  .learn-more {
    color: #ff00b3;
    font-size: .95rem;;
  }



  @media screen and (max-width:580px) {
    .main-container {
        display: flex;
        flex-direction:column;    
        align-items: center;
        gap:.4rem;
    }
    .margin-top-1{
          margin-top:0; 
      }

      .body-section {
        /* display: flex;
        flex-direction:column; */
        /* align-items: center; */
      }      
      .card-article {
        max-width:330px;
      }
      .card-pricing,.card-section {   
        flex-direction:column;
        /* display:block; */
        /* align-items: center; */
       }
       /* .footer,.form-section{
        margin-left:0;
       } */

      body {
      
        /* padding: .6%;     */
        /* padding-left:1%; */
      }

      .main2 {
        /* align-self: baseline; */
      }

      .clear-pricing {   
        font-size:1.3rem;
      }
      .info-section {
        padding:.8rem;
    }
    .card-col-1 {
        align-items: center;
    }
    .mobile-hide {
        display:none;
    }
    .form-section {
         margin-left: 0%;
  }
    }

    @media screen and (max-width:400
    px) {
        * {
            /* padding: .4%;   */
            font-size:99.9%;           
          }
          
    }