

/* Hero Section */
.hero-section {
    grid-column: 1/5;
    /* background: url('../src/components/assests/catgirl1.jpg'); */
    background: linear-gradient(to right, #162737, #0665b3, #3f7293, #3f7293,#162737);
    width: -webkit-fill-available;
    text-align: center;
    padding:1rem;
    font-family:system-ui;
    border: 1px solid orange;
    color:white;
    font-weight: 500;  
    font-size: 1.5rem;  
  }

/* @media screen and (max-width:700px) { */
@media screen and (max-width:450px) {
    .hero-section {
        color: white;
        background: var(--blue-main);
    }
}