
.head {
    width:100vw;    
    min-height: 54vh;  
    /* height: clamp(40vh, 46vh, 80vh); */
    /* 000e19 */
    /* background: linear-gradient(to bottom, #0b1e2d, #004b87 100%); */
    background: linear-gradient(to bottom, var(--blue-bottom-dark), #0665b3 100%);
    /* background: linear-gradient(to bottom, #05090d, #06a4be 100%);*/
    /* background: linear-gradient(to bottom, #05090d, #048196 100%); */
        /* background: linear-gradient(to bottom, #05090d, #007e94 100%); */
        /* background: linear-gradient(to bottom, #05090d, #3a6d8c 100%); */
    /* 0089bf  */
    /* background: linear-gradient(to bottom, #024077, #0665b3 100%); */
  
    display:grid;
    gap: .5rem;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows:.1fr .5fr 1fr .5fr;
    align-items: center;
    justify-items: center;
    color: white !important;
    text-decoration: none;
  }

  .headrow0 {
    grid-row:1/1;
  }
  .headrow1 {
    grid-row: 2/3;
  }
  .headrow2 {
    grid-row: 3/4;
  }
  .headrow3 {
    grid-row: 4/5;
  }
  .headrow4 {
    grid-row: 5/6;
  }
  .headrow-2-attributes {
    gap: 1rem;
    justify-content: center;
    text-wrap: nowrap;
    /* background-image: url('https://images.pexels.com/photos/879356/pexels-photo-879356.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'); */
    }
    .headerimg2{
    /* background-image: url('https://images.pexels.com/photos/1372014/pexels-photo-1372014.jpeg'); */
    background-repeat: no-repeat;
    opacity: .8;
    /* width: -webkit-fill-available;
    object-fit: fill; */
    position: absolute;
    top: 18%;
    width: fit-content;
    left: 15%;
  }
  .headerimg{

  }
  .tel {
    color: #2a9dfc !important;
    padding: 2%;
  }

  .logo {
    width: -webkit-fill-available;;
    /* background-image: url("../../assests/logo3.png"); */
    background-repeat: no-repeat;
    opacity: 0.8;
    /* position: absolute;
    top: 20px;
    right: 5%;
    left: 89%; */
  }
  .logo-container {
    width: 90px;
  }
  .logo-container2 {
    width: 16%;
    justify-self: center;
    margin-left:-100px;
  }
    
  .coinimg {
    width: 1.7rem;
    height: 1.7rem;
  }

.crypto-banner {
    /* background-color: var(--blue-bottom-dark); */
    background: linear-gradient(to bottom, #022643, #0072cf 100%);
    justify-content: space-evenly;
    align-items: center; 
    color: #cfa900;
    margin-top:-32px;
    font-size: 0.9rem;
    font-weight:600;
    padding: 0.1%;
}
.navbar {
    color: white !important;
    /* background-color: var(--blue-accent);     */
    /* background-color: #08467a;     */
       /* background: linear-gradient(to bottom, #024077, #0665b3 100%); */
       /* background: linear-gradient(to bottom, #093459, #0665b3 100%); */
       /* background: linear-gradient(to bottom, #007fed, #0665b3 100%); */
       background: linear-gradient(to bottom, #093459, #0665b3 100%);
    /* position: sticky; */
    list-style-type: none;
    justify-content: space-evenly;
    text-wrap: nowrap;   
    align-items: center; 
    padding:.1%;   
  }
  .sub-banner {
    /* max-width: 1250px; */
    /* border-radius: 2rem; */
   font-size: .75rem;
   border: 2px solid cornflowerblue;
    /* padding: 0px 20px; */
  }
.navul a{
    color: white !important;
    flex: 1;
    max-width: 60%;
    justify-content: space-between;
    text-decoration:none;
}
.sub-banner img {
  /* width: 15%; */
  height: 70%;
  /* aspect-ratio:2/1; */
}


.head a {
  text-decoration: none;
}


.modal-bg {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s;
}
.modal-dialog {
  max-width: 400px;
  max-height: 400px;
  background-color: white;
  border-radius: 3%;
  padding: 2%;
}
.bg-active {
  visibility: visible;
  opacity: 1;
}




.main-header {
   color: white ;
   font-size: 1.6rem ;
   font-family: sans-serif;;
}

.head-slogan {
  font-size:.8rem;
  font-weight: 600;
    line-height: 1.2;
     /* margin-top: 5%; */
}

      
.login-btn {
  padding: 3px 20px;
  /* background: transparent; */
  cursor: pointer;
  font-size: .6rem;
  border-radius:2rem;
}

.search-container {
  /* margin-top: -15px; */
}

@media screen and (min-width:1440px) { 
  .headerimg2{
    left: clamp(26%,30%,35%);
  }
  }

@media screen and (max-width:1040px) { 
.headerimg2{
  left: 7%;
}
}

@media screen and (max-width:1040px) {
 
.headerimg2{
  left: 10%;
}
}

@media screen and (max-width:890px) {
.crypto-banner {
  background: white;
  font-weight:700;
  }
.render-coins {
  gap: 3px;
}
.headerimg2{
  left:10%;
}
.head-slogan {
  font-size:.6rem;   
}
.main-header {

  font-size: 1.4rem ;
}
}

@media  (max-width: 720px){ 
  .head {
      /* font-size:85% ; */
      display:flex ;
      flex-wrap: wrap;
      justify-content: center;
      flex-direction: column;
      min-height: 250px; 
      max-height: 340px;
      /* flex-direction: column; */
  }
 
  .header-login {
      grid-row: 3/4;
      grid-column:1/2; 
      
      }

  .logo-container {
      width:16% ;
  }
  
  .row-2-attributes {
    justify-content: center;

  }
  .main-header {
    font-size:1rem;
  }  
  
}