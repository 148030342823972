
.form-section {
    grid-column: 1/5;
    text-align: center;
    /* margin-left:-6%; */
}
.contactForm {
 
  max-width: 400px;
  /* margin: 30px auto; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  grid-column: 2/5;
}
.contactForm input, .modal-submit-button, textarea{
  height: 50px;
  padding: 8px;
  font-size: 18px;
  caret-color: #0902cf;
  border-radius: .5rem;

}
.contactForm textarea{
  height: 100px;
}

.mainform {
    grid-column: 2/5;
    grid-row:3/4;
  }


