:root {
    --red-bottom-dark: #8a3f3f;
    --blue-bottom-dark: #0d1720;
    --blue-accent: #3f7293;
    --blue-main:#0665b3;
    --rich-black-fogra-29: hsl(207, 26%, 7%);
    --champagne-pink_20: hsla(23, 61%, 86%, 0.2);
    --independence_30: hsla(245, 17%, 29%, 0.3);
    --gray-x-11-gray: hsl(0, 0%, 73%);
    --champagne-pink: hsl(23, 61%, 86%);
    --spanish-gray: hsl(0, 0%, 60%);
    --sonic-silver: hsl(0, 0%, 47%);
    --deep-saffron: hsl(32, 100%, 59%);
    --dark-orange: hsl(28, 100%, 58%);
    --desert-sand: hsl(23, 49%, 82%);
    --isabelline: hsl(38, 44%, 96%);
    --gainsboro: hsl(0, 0%, 87%);
    --tangerine: hsl(31, 84%, 50%);
    --cinnabar: hsl(3, 90%, 55%);
    --black_95: hsla(0, 0%, 0%, 0.95);
    --cultured: hsl(0, 0%, 93%);
    --white: hsl(0, 0%, 100%);
    --black: hsl(0, 0%, 0%);
    --onyx: hsl(0, 0%, 27%);
    --ff-shadows-into-light: "Shadows Into Light", cursive;
    --ff-roboto: "Roboto", sans-serif;
    --ff-rubik: "Rubik", sans-serif;
    --fs-1: 3.2rem;
    --fs-2: 2.2rem;
    --fs-3: 1.8rem;
    --fs-4: 1.4rem;
    --fs-5: 1.2rem;
    --fw-500: 500;
    --fw-600: 600;
    --fw-700: 700;
    --section-padding: 60px;
    --shadow-1: 0 1px 4px hsla(0, 0%, 0%, 0.2);
    --shadow-2: 0 1px 2px hsla(0, 0%, 0%, 0.2);
    --transition-1: 0.25s ease;
    --transition-2: 0.5s ease;
    --clip-path-1: polygon(0 40%, 100% 0%, 100% 100%, 0 100%);
    --clip-path-2: polygon(0 0%, 100% 0%, 100% 100%, 0 100%);
  }
*{
    /* overflow-x: hidden !important;  */
    scroll-behavior:smooth;
}
body{      
    width: 100vw;   
    margin: 0px;    
    overflow-x: hidden; 
    font-family: "sans-serif",system-ui, -apple-system ,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
/* line-height: 1.4rem; */
}
ul{
    padding-left: 0;
}

.app {
    display:flex;
    flex-direction:column;
    gap: 2rem;
    align-items:center;
  }

.sticky{
    position: sticky;
    top: 0px;
    z-index: 10;
}

.width100{
    width:100vw;
    }

    
.width90{
    width:90vw;
    }
.width80{
width:80vw;
}

.width75{
    width:75vw;
    
    }


    .width70{
        width:70vw;
        
        }

        .width65{
            width:65vw;
            
            }

.width60{
     width:60vw;        
      }

    .width50{
        width:50vw;        
                }

                .width40{
                    width:40vw;
                    
                    }

                .width30{
                    width:30vw;
                    
                    }
                    .width20{
                        width:20vw;
                        
                        }

                        .width10{
                            width:15vw;
                            
                            }

                            .width5{
                                width:5vw;
                                
                                }
    .max2000{
        max-width: 2000px !important;
    }

    .padding1{
        padding:1%;
        
            }
    .padding2{
padding:2%;

    }
h1{
    /* font-weight: 700 !important; */
}


.margin{
    margin:auto  !important;
    right: 0;
    left: 0;
}

.margin2{
margin:2%;

}
.marginleft2{
margin-left: 2ch;
}

.justify{
    justify-content: center  !important;
}

.justifystart{
    justify-content: flex-start  !important;
}

.justifyend{
    justify-content: flex-end;
}
.justifyaround{
    justify-content:space-around !important;
}

.justifybetween{
    justify-content:space-between  !important;
}
.justifyevenly {
    justify-content: space-evenly;
}

.aligncontent{
    align-content: center  !important;
}


.alignitems{
   
align-items: center  !important;
}



.textalign{
    text-align: center !important;
}


.flex{
    display: flex;
    flex-wrap: nowrap;
}

.flexwrap{
    display: flex;
    flex-wrap: wrap;
}

.flexcol{
    display: flex;
    flex-direction: column;
}

.relative{
    position:relative;
}

.absolute{
    position: absolute;
   
}

.absolutetop{
    position: absolute;
     top:0% ;
     right:0;
     left:0;

}

.absolutebottom{
    position: absolute;
    bottom:0;
    right:0;
    left:0;
}
.marginright1{
    margin-right: 1%;
}
.marginright2{
    margin-right: 2%;
}
.marginright5{
    margin-right: 5%;
}
.margintop{
    margin-top:5%  !important;
}

.margintop10{
    margin-top:10%  !important;
}

.margintop15{
    margin-top:15%  !important;
}

.margintop25{
    margin-top:25%  !important;
}

.margintop50{
    margin-top:50%  !important;    
}
.overflow{
    overflow: hidden !important;
}

.hrblue{

    background-color:  2px solid  #01a6e7;
    width:1px;
    height:500px;
    display: inline-block;
    position: absolute;
    margin:10%;
    opacity: .7;
}
.hrsticky{
    /* position:sticky; */
    top:15%;
    /* bottom:25%; */

}
.show-on-sticky-active {
    display: none;
  }
  
  .show-on-sticky-active.show {
    display: block; /* or any other display value you prefer */
  }

.footer{
    width:100vw;
}

.font500{
font-weight: 500;
}

.font600{
    font-weight: 600;    
}

.font700{
    font-weight: 700;
}

.drop-shadow-filter {
    filter: drop-shadow(9px 5px 5px rgba(0, 0, 0, 0.5));
}
.box-shadow {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.box-shadow2 {
    box-shadow: -5px 8px 5px rgba(0, 0, 0, 0.3);
}

.text-shadow {
    text-shadow: 2px 2px 4px #000000;
}
.text-shadow-header {
    text-shadow: -1px 2px 3px #000000;
}
.text-shadow2 {
    text-shadow: 1px 2px 1px #000000;
}

.desktophide { 
    display:none;
}
.hide { 
    display:none;
}
.show { 
    display:block;
}

.text-wrap {
    text-wrap: nowrap;
}

/* img { */
    /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);  */
    /* filter: drop-shadow(2px 2px 5px rgba(0, 0, .3, 0.6)); */
/* } */

@media  (max-width: 700px){ 
   
    .mobilehide { 
        display:none;
    }
    .visibility { 
        visibility:hidden;
    }
    .desktophide { 
        display: block;
    }   
    
    
}

  @media  (max-width: 950px){ 
    .fontsmall{
    
     font-size: 70%;
     width:100vw;
     height:50%;
       
   
       }
     }

     * {
        margin: 0px;
     
        overflow-x: clip  !important;
      }

      @media (max-width:668px){
          body{
              /* font-size:.8em; */

          }
      }