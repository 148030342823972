.modal {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to bottom, #354f79, #0062ff,#2462ea, #354f79);
    padding: 20px;    
    min-width:20rem;
    z-index:8;
    border-radius:1rem;
    aspect-ratio: 9/10;
      transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
      /* border: 2.5px solid cornflowerblue; */
      border: 2.5px solid chartreuse;
}
.modal-content.open {
    opacity: 1;
    transform: translate(-50%, -50%);
  }

.modal-submit-button {
    width: 75%;
    background-color: #0d6efd;
    color: #fff;
    margin: 5px;
    cursor: pointer;
    align-self: center;
    transition: var(--transition-1);
  }
  .modal-submit-button:is(:hover,:focus) {
    background-color:var(--rich-black-fogra-29);
  }
  /* .footer-form .btn:is(:hover, :focus) {
    background-color: var(--rich-black-fogra-29);
} */

  .modal-close-btn {
    cursor: pointer;
    text-wrap: nowrap;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 6;
    transition: opacity 0.3s ease-in-out;
  }
  .modal-overlay.open {
    opacity: 1;
  }
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    font-weight: bold;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .btn-close {
    position: absolute;
    right: 20px;
    top: 10px;
    box-sizing: content-box;
    width: .9em;
    height: .9em;
    padding: 0.05em;
    color: #000;
    /* background: transparent
      url(
        data:image/svg + xml;charset=utf-8,
        %3Csvgxmlns="http://www.w3.org/2000/svg"viewBox="0 0 16 16"fill="%23000"%3E%3Cpathd="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z"/%3E%3C/svg%3E
      )
      50%/1em auto no-repeat; */
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    cursor: pointer;
  }