.navbar-mobile {
    display: flex;
    justify-content: space-around;
    align-items: center;
    list-style-type: none;
    width: clamp(30%,60%,70%);
    border-radius:2rem;
  }
  
  .mobileheader {
    color:white;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding: 0;
    text-wrap: nowrap;
  }

  .mobile-text-header {
    font-size: .7rem;
  }


  .menu-icon {
    cursor: pointer;
    display: none;
  }
  
  .nav-links {
    display: flex;
    justify-content: space-around;
    width: h60%;
  }
  
  .nav-links li {
    list-style: none;
  }
  
  .nav-links a {
    color: black;
    text-decoration: none;
    font-size: 14px;
  }
  
  @media screen and (max-width: 768px) {
    .nav-links {
      position: absolute;
      right: 0px;
      height: 92vh;
      top: 8vh;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      transform: translateX(100%);
      transition: transform 0.5s ease-in-out;
    }
  
    .nav-links.active {
      transform: translateX(0%);
    }
  
    .menu-icon {
      display: block;
    }
  }
  